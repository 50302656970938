<template>
  <div class="P-users-list position-relative">
    <div class="G-page-component">
      <div class="G-page-title-block G-flex G-justify-between G-align-center">
        <div class="G-component-title d-flex">
          <h3 class="secondary--text">Pro Tips</h3>
        </div>
      </div>
      <div
        :class="{ 'h-72vh': !selectedVideo }"
        class="G-component-body pa-5 page-background G-justify-between"
      >
        <v-row class="justify-space-between" no-gutters>
          <v-col :cols="3">
            <div>
              <!-- Form part -->
              <transition name="slide">
                <div
                  v-if="isOpenForm"
                  class="d-flex flex-column w-max-content mb-4"
                >
                  <div class="P-categories-types">
                    <v-radio-group
                      v-model="formData.categoryType"
                      :error="$v.formData.categoryType.$error"
                      class="P-radio-group d-flex flex-column"
                      row
                    >
                      <ott-radio value="provider" label="For Providers" />
                      <ott-radio value="provider_cashier" label="Both" />
                    </v-radio-group>
                  </div>

                  <div class="G-align-center">
                    <ott-input
                      v-model="formData.categoryInp"
                      :error="$v.formData.categoryInp.$error"
                      label="Category"
                    />
                    <span
                      @click="addSubInputs"
                      title="Add Sub Category"
                      class="mdi mdi-plus-circle-outline cursor-pointer ms-2 text-20"
                    />
                  </div>
                  <transition name="slide">
                    <div
                      v-if="subcategories.length"
                      class="d-flex flex-column w-max-content"
                    >
                      <div
                        v-for="(item, index) of subcategories"
                        class="G-align-center w-max-contet"
                        :key="index"
                      >
                        <ott-input
                          v-model="item.text"
                          :label="`Sub Category ${index + 1}`"
                          class="mt-2"
                        />

                        <span
                          @click="subcategories.splice(index, 1)"
                          title="Delete"
                          class="mdi mdi-close cursor-pointer ms-2 text-20"
                        ></span>
                      </div>
                    </div>
                  </transition>

                  <div class="save-btn mt-2">
                    <ott-button-loader
                      @onClick="save"
                      button-text="Save"
                      class-name="primary-sm-btn w-max-content"
                      :is-loading="isLoading"
                    />
                  </div>
                </div>
              </transition>

              <!-- Add Categories part -->
              <div class="w-max-content" :class="{ 'mb-4': categories.length }">
                <ott-button
                  v-if="authProvider.type === 0"
                  :only-icon="true"
                  :click="toggleCategory"
                  title="Add Category"
                  custom-class="w-max-content P-add-category"
                  :icon-only-class="`mdi ${
                    isOpenForm
                      ? 'mdi-minus-circle-outline'
                      : 'mdi-plus-circle-outline'
                  }`"
                />
              </div>

              <!-- Categories part -->
              <transition name="fade">
                <div v-if="categories.length" class="P-help-sidebar pa-3 mb-2">
                  <ott-input
                    v-model="search"
                    @emitFunc="filterCategories"
                    prependInnerIcon="mdi mdi-magnify  neutral--text"
                    label="Search Category"
                    custom-input-class="mb-2"
                  />

                  <div v-if="filteredCategories.length" class="pa-0">
                    <transition name="fade">
                      <p
                        v-if="isExistProviderCategory"
                        class="mb-2 ps-2 P-categories-type"
                      >
                        For Providers
                      </p>
                    </transition>

                    <draggable
                      v-model="filteredCategories"
                      @end="onDragEnd"
                      :options="dragOptions"
                      :disabled="authProvider.type !== 0"
                    >
                      <!-- Provider Categories -->
                      <template v-for="category in filteredCategories">
                        <li
                          v-if="category?.type === 'provider'"
                          :key="category.id"
                        >
                          <!-- Parent Category -->
                          <b class="G-align-center">
                            <span v-if="!category.isEdit">{{
                              category.text
                            }}</span>

                            <div v-else class="d-flex flex-column">
                              <div class="P-categories-types">
                                <v-radio-group
                                  v-model="category._type"
                                  class="P-radio-group d-flex flex-column"
                                  row
                                >
                                  <ott-radio
                                    value="provider"
                                    label="For Providers"
                                  />
                                  <ott-radio
                                    value="provider_cashier"
                                    label="Both"
                                  />
                                </v-radio-group>
                              </div>

                              <ott-input
                                v-model="category.text"
                                customInputClass="mb-1"
                              />
                            </div>

                            <div
                              v-if="authProvider.type === 0"
                              class="actions-cont"
                            >
                              <span
                                v-if="!category.isEdit"
                                @click="category.isEdit = true"
                                title="Edit"
                                class="mdi mdi-pencil me-1 ms-2 cursor-pointer"
                              ></span>

                              <span
                                v-if="!category.isEdit"
                                @click="
                                  openCategoryDeleteModal(
                                    'category',
                                    category.id
                                  )
                                "
                                title
                                class="mdi mdi-close cursor-pointer"
                              ></span>

                              <div
                                v-if="category.isEdit"
                                class="G-align-end mb-1 h-52"
                              >
                                <span
                                  @click="
                                    updateCategory({
                                      categoryName: category.text,
                                      category: category.id,
                                      newSubcategories:
                                        category.newSubcategories,
                                      type: category._type,
                                    })
                                  "
                                  class="mdi mdi-check ms-2 me-1 cursor-pointer"
                                ></span>

                                <span
                                  @click="addSubsForParent(category.id)"
                                  title="Add Sub Category"
                                  class="mdi mdi-plus-circle-outline cursor-pointer"
                                ></span>
                              </div>
                            </div>
                          </b>

                          <!-- Sub Categories -->
                          <template v-if="category?.subcategories">
                            <draggable
                              v-model="category.subcategories"
                              @end="onDragEnd"
                              :options="subDragOptions"
                              :disabled="authProvider.type !== 0"
                            >
                              <li
                                v-for="sub in category.subcategories"
                                :key="sub.id"
                                class="G-align-center ps-4"
                              >
                                <span
                                  v-if="!sub.isEdit"
                                  @click="subItemClick(sub.id, sub.video)"
                                  :class="{
                                    'active-category':
                                      activeCategory === sub.id,
                                  }"
                                  >{{ sub.text }}</span
                                >
                                <ott-input
                                  v-else
                                  v-model="sub.text"
                                  customInputClass="mb-1"
                                />

                                <div
                                  v-if="authProvider.type === 0"
                                  class="actions-cont"
                                >
                                  <span
                                    v-if="!sub.isEdit"
                                    @click="sub.isEdit = true"
                                    title="Edit"
                                    class="mdi mdi-pencil me-1 ms-2 cursor-pointer"
                                  ></span>

                                  <span
                                    v-if="!sub.isEdit"
                                    @click="
                                      openCategoryDeleteModal(
                                        'category',
                                        sub.id
                                      )
                                    "
                                    title="Delete"
                                    class="mdi mdi-close cursor-pointer"
                                  ></span>

                                  <span
                                    v-if="sub.isEdit"
                                    @click="
                                      updateCategory({
                                        categoryName: sub.text,
                                        category: sub.id,
                                        type: category._type,
                                      })
                                    "
                                    class="mdi mdi-check ms-2 cursor-pointer"
                                  ></span>
                                </div>
                              </li>
                            </draggable>
                          </template>

                          <!-- Parent sub categories inputs -->
                          <transition name="slide">
                            <div
                              v-if="category.newSubcategories.length"
                              class="d-flex flex-column w-max-content"
                            >
                              <div
                                v-for="(
                                  item, index
                                ) of category.newSubcategories"
                                class="G-align-center w-max-contet"
                                :key="index"
                              >
                                <ott-input
                                  v-model="item.text"
                                  label="Sub Category"
                                  class="mt-1"
                                />

                                <span
                                  @click="
                                    category.newSubcategories.splice(index, 1)
                                  "
                                  class="mdi mdi-close cursor-pointer ms-2"
                                ></span>
                              </div>
                            </div>
                          </transition>
                        </li>
                      </template>
                    </draggable>

                    <transition name="fade">
                      <p
                        v-if="isExistCashierCategory"
                        class="my-2 ps-2 P-categories-type"
                      >
                        For Cashiers
                      </p>
                    </transition>

                    <draggable
                      v-model="filteredCategories"
                      @end="onDragEnd"
                      :options="dragOptions"
                      :disabled="authProvider.type !== 0"
                    >
                      <!-- Both Categories -->
                      <template v-for="category in filteredCategories">
                        <li
                          v-if="
                            category?.type === 'cashier' ||
                            category?.type === 'provider_cashier'
                          "
                          :key="category.id"
                        >
                          <!-- Parent Category -->
                          <b class="G-align-center">
                            <span v-if="!category.isEdit">{{
                              category.text
                            }}</span>

                            <div v-else class="d-flex flex-column">
                              <div class="P-categories-types">
                                <v-radio-group
                                  v-model="category._type"
                                  class="P-radio-group d-flex flex-column"
                                  row
                                >
                                  <ott-radio
                                    value="provider"
                                    label="For Providers"
                                  />
                                  <ott-radio
                                    value="provider_cashier"
                                    label="Both"
                                  />
                                </v-radio-group>
                              </div>

                              <ott-input
                                v-model="category.text"
                                customInputClass="mb-1"
                              />
                            </div>

                            <div
                              v-if="authProvider.type === 0"
                              class="actions-cont"
                            >
                              <span
                                v-if="!category.isEdit"
                                @click="category.isEdit = true"
                                title="Edit"
                                class="mdi mdi-pencil me-1 ms-2 cursor-pointer"
                              ></span>

                              <span
                                v-if="!category.isEdit"
                                @click="
                                  openCategoryDeleteModal(
                                    'category',
                                    category.id
                                  )
                                "
                                title
                                class="mdi mdi-close cursor-pointer"
                              ></span>

                              <div
                                v-if="category.isEdit"
                                class="G-align-end mb-1 h-52"
                              >
                                <span
                                  @click="
                                    updateCategory({
                                      categoryName: category.text,
                                      category: category.id,
                                      newSubcategories:
                                        category.newSubcategories,
                                      type: category._type,
                                    })
                                  "
                                  class="mdi mdi-check ms-2 me-1 cursor-pointer"
                                ></span>

                                <span
                                  @click="addSubsForParent(category.id)"
                                  title="Add Sub Category"
                                  class="mdi mdi-plus-circle-outline cursor-pointer"
                                ></span>
                              </div>
                            </div>
                          </b>

                          <!-- Sub Categories -->
                          <template v-if="category?.subcategories">
                            <draggable
                              v-model="category.subcategories"
                              @end="onDragEnd"
                              :options="subDragOptions"
                              :disabled="authProvider.type !== 0"
                            >
                              <transition-group
                                type="transition"
                                name="sublist"
                              >
                                <li
                                  v-for="(
                                    sub, subKey
                                  ) in category.subcategories"
                                  :key="subKey"
                                  class="G-align-center ps-4"
                                >
                                  <span
                                    v-if="!sub.isEdit"
                                    @click="subItemClick(sub.id, sub.video)"
                                    :class="{
                                      'active-category':
                                        activeCategory === sub.id,
                                    }"
                                    >{{ sub.text }}</span
                                  >
                                  <ott-input
                                    v-else
                                    v-model="sub.text"
                                    customInputClass="mb-1"
                                  />

                                  <div
                                    v-if="authProvider.type === 0"
                                    class="actions-cont"
                                  >
                                    <span
                                      v-if="!sub.isEdit"
                                      @click="sub.isEdit = true"
                                      title="Edit"
                                      class="mdi mdi-pencil me-1 ms-2 cursor-pointer"
                                    ></span>

                                    <span
                                      v-if="!sub.isEdit"
                                      @click="
                                        openCategoryDeleteModal(
                                          'category',
                                          sub.id
                                        )
                                      "
                                      title="Delete"
                                      class="mdi mdi-close cursor-pointer"
                                    ></span>

                                    <span
                                      v-if="sub.isEdit"
                                      @click="
                                        updateCategory({
                                          categoryName: sub.text,
                                          category: sub.id,
                                          type: category._type,
                                        })
                                      "
                                      class="mdi mdi-check ms-2 cursor-pointer"
                                    ></span>
                                  </div>
                                </li>
                              </transition-group>
                            </draggable>
                          </template>

                          <!-- Parent sub categories inputs -->
                          <transition name="slide">
                            <div
                              v-if="category.newSubcategories.length"
                              class="d-flex flex-column w-max-content"
                            >
                              <div
                                v-for="(
                                  item, index
                                ) of category.newSubcategories"
                                class="G-align-center w-max-contet"
                                :key="index"
                              >
                                <ott-input
                                  v-model="item.text"
                                  label="Sub Category"
                                  class="mt-1"
                                />

                                <span
                                  @click="
                                    category.newSubcategories.splice(index, 1)
                                  "
                                  class="mdi mdi-close cursor-pointer ms-2"
                                ></span>
                              </div>
                            </div>
                          </transition>
                        </li>
                      </template>
                    </draggable>
                  </div>
                </div>
              </transition>
            </div>
          </v-col>

          <!-- Video part -->
          <v-col :cols="8">
            <transition name="fade">
              <div
                v-if="activeCategory && filteredCategories.length"
                class="G-flex-center w-100 h-100"
              >
                <input
                  ref="file"
                  type="file"
                  accept="video/*"
                  @change="uploadFile"
                  multiple
                  hidden
                />
                <div
                  v-if="!selectedVideo && authProvider.type === 0"
                  class="G-flex-center flex-column w-90"
                >
                  <img
                    v-if="!fileUploadProgress"
                    @click="upload()"
                    src="@/assets/images/upload-photo.png"
                    class="mb-3 cursor-pointer"
                    width="75"
                  />

                  <ott-button-loader
                    v-if="!fileUploadProgress"
                    button-text="Upload"
                    class-name="primary-sm-btn mb-3"
                    @onClick="upload()"
                  />

                  <v-progress-linear
                    v-if="fileUploadProgress"
                    v-model="fileUploadProgress"
                    color="primary"
                    class="w-70"
                    height="20"
                    rounded
                    striped
                  >
                    <template v-slot:default="{ value }">
                      <strong class="white--text">{{ value }}%</strong>
                    </template>
                  </v-progress-linear>
                </div>

                <div
                  v-if="selectedVideo"
                  class="video-cont text-center pa-2 w-100"
                >
                  <div class="G-align-center flex-column">
                    <video
                      v-if="showVideo"
                      controls
                      :controlsList="
                        authProvider.type !== 0 ? 'nodownload' : 'download'
                      "
                    >
                      <source
                        :src="`${baseApiUrl + 'files/icon/' + selectedVideo}`"
                      />
                    </video>

                    <v-progress-linear
                      v-if="fileUploadProgress"
                      v-model="fileUploadProgress"
                      class="mt-3"
                      color="primary"
                      height="20"
                      rounded
                      striped
                    >
                      <template v-slot:default="{ value }">
                        <strong class="white--text">{{ value }}%</strong>
                      </template>
                    </v-progress-linear>

                    <div
                      v-if="authProvider.type === 0 && !fileUploadProgress"
                      class="P-buttons G-flex mt-3"
                    >
                      <ott-button-loader
                        button-text="Upload New Video"
                        class-name="primary-sm-btn"
                        @onClick="upload()"
                      />

                      <ott-button
                        :only-icon="true"
                        :click="openVideoDeleteModal"
                        title="Delete Video"
                        custom-class="w-max-content P-delete-video ms-2"
                        icon-only-class="mdi mdi-close"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </v-col>
        </v-row>
      </div>
    </div>

    <WarningModal
      v-if="showWarningModal"
      :isModalOpen="showWarningModal"
      :isDangerButton="true"
      :isLoading="warningModalIsLoading"
      :modalHeading="warningModalHeading"
      :modalText="warningModalText"
      btnText="Delete"
      @closeModal="showWarningModal = false"
      @btnFunction="warningModalBtnFunc()"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

import OttButton from "@/components/vuetifyComponents/OttButton";
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";
import OttRadio from "@/components/vuetifyComponents/OttRadio";
import WarningModal from "@/components/WarningModal";
import Draggable from "vuedraggable";

export default {
  name: "Help",
  components: {
    OttButton,
    OttInput,
    OttButtonLoader,
    OttRadio,
    WarningModal,
    Draggable,
  },
  computed: {
    ...mapState({
      categoriesList: (state) => state.help.categoriesList,
      authProvider: (state) => state.auth.user.provider,
      authUser: (state) => state.auth.user,
      fileUploadProgress: (state) => state.fileUpload.fileUploadProgress,
    }),

    isExistProviderCategory() {
      let boolean = false;

      if (this.filteredCategories.length) {
        this.filteredCategories.map((item) => {
          if (item.type === "provider") {
            boolean = true;
          }
        });
      }

      return boolean;
    },

    isExistCashierCategory() {
      let boolean = false;

      if (this.filteredCategories.length) {
        this.filteredCategories.map((item) => {
          if (item.type === "cashier" || item.type === "provider_cashier") {
            boolean = true;
          }
        });
      }

      return boolean;
    },

    warningModalHeading() {
      return `Delete this ${this.deleteType}?`;
    },

    warningModalText() {
      return `You're about to <span class='text-lowercase'><ins>delete</ins></span> this ${this.deleteType}. <br> If you're not sure, you can cancel this operation.`;
    },

    baseApiUrl() {
      return process.env.VUE_APP_API_URL;
    },

    // filteredCategories() {
    //   if (this.search.trim()) {
    //     return this.categories.filter(
    //       (category) =>
    //         category.text.toLowerCase().includes(this.search.toLowerCase()) ||
    //         category.subcategories.some((subCategory) =>
    //           subCategory.text.toLowerCase().includes(this.search.toLowerCase())
    //         )
    //     );
    //   }
    //   return this.categories;
    // },
  },
  watch: {
    isOpenForm(newVal) {
      if (!newVal) {
        this.search = "";
        this.formData.categoryType = "";
        this.formData.categoryInp = "";
        this.subcategories = [];
        this.activeCategory = null;
        this.selectedVideo = null;
        this.showVideo = false;
        this.showWarningModal = false;
        this.warningModalIsLoading = false;
        this.deleteType = "";
        this.selectedCategory = false;

        this.$v.formData.$reset();
      }
    },

    selectedVideo() {
      this.showVideo = false;
      setTimeout(() => {
        this.showVideo = true;
      }, 200);
    },
  },
  validations: {
    formData: {
      categoryType: {
        required,
      },
      categoryInp: {
        required,
      },
    },
  },
  data() {
    return {
      search: "",
      selectedItem: null,
      categories: [],
      filteredCategories: [],
      isOpenForm: false,
      formData: {
        categoryType: "",
        categoryInp: "",
      },
      subcategories: [],
      activeCategory: null,
      selectedVideo: null,
      showVideo: false,
      showWarningModal: false,
      warningModalIsLoading: false,
      deleteType: "",
      selectedCategory: null,
      dragging: false,

      dragOptions: {
      },
      subDragOptions: {
        group: "subcategories",
      },

      isLoading: false,
    };
  },
  created() {
    this.getCategoriesList();
  },
  methods: {
    ...mapActions({
      getCategories: "help/getCategories",
      addCategory: "help/addCategory",
      deleteCategoryAction: "help/deleteCategory",
      editCategory: "help/editCategory",
      uploadImage: "fileUpload/uploadImage",
    }),

    onDragEnd() {
      //check if categories or subcategories not has a number, add number key
      if (this.filteredCategories.length) {
        this.filteredCategories.forEach(async (parent, index) => {
          await this.editCategory({
            category: parent.id,
            number: index,
          });

          // add number keys for subcategories
          if (parent?.subcategories) {
            parent.subcategories.forEach(async (sub, subIndex) => {
              await this.editCategory({
                category: sub.id,
                number: subIndex,
                parent: parent.id,
                type: parent.type,
              });
            });
          }
        });
      }
    },

    filterCategories() {
      if (this.search.trim()) {
        this.filteredCategories = [];
        this.filteredCategories = this.categories.filter(
          (category) =>
            category.text.toLowerCase().includes(this.search.toLowerCase()) ||
            category.subcategories.some((subCategory) =>
              subCategory.text.toLowerCase().includes(this.search.toLowerCase())
            )
        );
      }
    },

    async dragged(e) {
      this.dragging = false;
      //check if categories or subcategories not has a number, add number key
      if (this.categories.length) {
        this.categories.forEach(async (parent, index) => {
          await this.editCategory({
            category: parent.id,
            number: index,
          });

          // add number keys for subcategories
          if (parent?.subcategories) {
            parent.subcategories.forEach(async (sub, subIndex) => {
              await this.editCategory({
                category: sub.id,
                number: subIndex,
                parent: parent.id,
                type: parent.type,
              });
            });
          }
        });
      }
    },

    subItemClick(id, video) {
      this.activeCategory = id;
      this.selectedVideo = video;
    },

    createCategoriesList() {
      this.categories = [];
      if (this.categoriesList.length) {
        this.categoriesList.forEach(async (item) => {
          if (!item?.parent) {
            this.categories.push({
              text: item.categoryName,
              id: item.id,
              type: item.type,
              _type: item.type,
              subcategories: [],
              newSubcategories: [],
              isEdit: false,
              number: item?.number,
            });
          }

          // Find the corresponding category in categories
          const parentCategory = this.categories.find(
            (category) => category.id === item.parent
          );

          // If the parent category is found, add the current item to its subcategories
          if (parentCategory) {
            if (!parentCategory.subcategories) {
              parentCategory.subcategories = [];
            }
            parentCategory.subcategories.push({
              text: item.categoryName,
              id: item.id,
              type: item.type,
              _type: item.type,
              parent: parentCategory.id,
              isEdit: false,
              video: item?.video,
              number: item?.number,
            });
          }
        });
      }

      if (this.categories.length) {
        // Custom comparator function for sorting by the "number" key
        const sortByNumber = (a, b) => a.number - b.number;

        // Sorting the main array by the "number" key
        this.categories.sort(sortByNumber);

        // Sorting subcategories arrays for each main object
        this.categories.forEach((item) => {
          item.subcategories.sort(sortByNumber);
        });
      }

      this.filteredCategories = [...this.categories];
    },

    toggleCategory() {
      this.isOpenForm = !this.isOpenForm;
    },

    addSubInputs() {
      this.subcategories.push({
        text: "",
      });
    },

    async updateCategory({
      categoryName = "",
      category = null,
      newSubcategories = [],
      type = null,
    }) {
      //category update
      const editResponse = await this.editCategory({
        categoryName,
        category,
        type,
      });

      if (editResponse) {
        await this.getCategoriesList();
      }

      // Add new sub categories for parent category
      if (newSubcategories.length && type) {
        for (const item of newSubcategories) {
          if (item.text.trim()) {
            await this.addCategory({
              categoryName: item.text,
              type: type,
              parent: category,
            });
          }
        }

        await this.getCategoriesList();
      }
    },

    async getCategoriesList() {
      this.isLoading = true;
      await this.getCategories();
      if (this.categoriesList.length) {
        this.createCategoriesList();
      } else {
        this.categories = [];
      }

      // //check if categories or subcategories not has a number, add number key
      if (this.categories.length) {
        await this.setCategoriesNumbers().finally(
          () => (this.isLoading = false)
        );
      }

      this.isLoading = false;
    },

    async setCategoriesNumbers() {
      //check if categories or subcategories not has a number, add number key
      if (this.categories.length) {
        let notHasNumber = 0;

        this.categoriesList.map((item) => {
          if (!("number" in item)) {
            notHasNumber++;
          }
        });

        if (notHasNumber > 0) {
          this.categories.forEach(async (parent, index) => {
            if (parent.number === undefined) {
              const response = await this.editCategory({
                category: parent.id,
                number: index,
              });

              if (response) {
                if (this.categoriesList.length) {
                  await this.getCategories().finally(
                    () => (this.isLoading = false)
                  );
                  this.createCategoriesList();
                } else {
                  this.categories = [];
                }
              }
            }

            // add number keys for subcategories
            if (parent?.subcategories) {
              parent.subcategories.forEach(async (sub, subIndex) => {
                if (sub.number === undefined) {
                  const response = await this.editCategory({
                    category: sub.id,
                    number: subIndex,
                  });

                  if (response) {
                    if (this.categoriesList.length) {
                      await this.getCategories().finally(
                        () => (this.isLoading = false)
                      );
                      this.createCategoriesList();
                    } else {
                      this.categories = [];
                    }
                  }
                }
              });
            }
          });
        }
      }
    },

    async save() {
      this.$v.formData.$touch();

      if (
        !this.$v.formData.$error &&
        this.formData.categoryInp.trim() &&
        this.formData.categoryType
      ) {
        // Check if categoryName already exists
        const isExistParentCategory = this.categoriesList.find(
          (category) => category.categoryName === this.formData.categoryInp
        );

        if (!isExistParentCategory || !this.categoriesList.length) {
          const parentCategory = await this.addCategory({
            categoryName: this.formData.categoryInp,
            type: this.formData.categoryType,
          });

          if (parentCategory) {
            await this.getCategoriesList();
          }

          if (parentCategory?.id && this.subcategories.length) {
            for (const item of this.subcategories) {
              if (item.text.trim()) {
                await this.addCategory({
                  categoryName: item.text,
                  type: this.formData.categoryType,
                  parent: parentCategory?.id,
                });
              }
            }

            await this.getCategoriesList();
          }
        }

        this.isOpenForm = false;
      }
    },

    openCategoryDeleteModal(type = "", category = null) {
      this.deleteType = type;
      this.selectedCategory = category;
      this.showWarningModal = true;
    },

    openVideoDeleteModal() {
      this.deleteType = "video";
      this.showWarningModal = true;
    },

    async warningModalBtnFunc() {
      this.warningModalIsLoading = true;
      if (this.deleteType === "category") {
        await this.deleteCategory(this.selectedCategory).finally(() => {
          this.warningModalIsLoading = false;
          this.showWarningModal = false;
        });
      }

      if (this.deleteType === "video") {
        await this.deleteSelectedVideo().finally(() => {
          this.warningModalIsLoading = false;
          this.showWarningModal = false;
        });
      }
    },

    async deleteCategory(category) {
      if (category === this.activeCategory) {
        this.activeCategory = null;
      }
      //Delete parent category
      await this.deleteCategoryAction(category);

      // delete all parent's sub categories
      if (this.categoriesList.length) {
        this.categoriesList.map(async (item) => {
          if (item?.parent === category) {
            await this.deleteCategoryAction(item.id);
          }
        });
      }
      await this.getCategoriesList();
    },

    async deleteSelectedVideo() {
      this.warningModalIsLoading = true;

      await this.editCategory({
        category: this.activeCategory,
        video: "",
      }).finally(() => (this.warningModalIsLoading = false));

      this.selectedVideo = null;
      await this.getCategoriesList();
      this.showWarningModal = false;
      this.deleteType = "";
      this.selectedCategory = null;
    },

    upload() {
      this.$refs.file.click();
    },

    uploadFile(data) {
      if (this.activeCategory && this.filteredCategories.length) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.uploadImage(data.target.files[0]).then(async (video) => {
            this.selectedVideo = video?.id;
            await this.editCategory({
              video: video?.id,
              category: this.activeCategory,
            });

            await this.getCategoriesList();
          });
        };
        reader.readAsDataURL(data.target.files[0]);
      }
    },

    addSubsForParent(parent) {
      if (this.filteredCategories.length) {
        this.categories.map((item) => {
          if (item.id === parent) {
            item.newSubcategories.push({
              text: "",
            });
          }
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/variables";

.P-help-sidebar {
  box-shadow: 1px 1px 3px 2px lightgrey;
  border-radius: 5px;

  ul {
    max-height: 600px;
    overflow-y: auto;
  }

  & li {
    list-style-type: none;
    cursor: pointer;
  }

  & .actions-cont {
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s;
  }

  & li:hover > b > .actions-cont {
    visibility: visible;
    opacity: 1;
  }

  & li:hover > .actions-cont {
    visibility: visible;
    opacity: 1;
  }
}

.plus-sub-category {
  font-size: $txt20;
}

.text-20 {
  font-size: $txt20;
}

.P-add-category {
  .only-icon-btn {
    font-size: 20px;
    color: $primary-color;
  }
}

.mdi-close {
  &:hover {
    color: red;
  }
}

.mdi-pencil {
  &:hover {
    color: rgb(240, 190, 97);
  }
}

.mdi-plus-circle-outline {
  &:hover {
    color: $primary-color;
  }
}

.P-categories-types {
  .v-input--selection-controls {
    margin-top: 0 !important;
  }
}

.active-category {
  color: $primary-color;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.w-90 {
  width: 90%;
}

.w-70 {
  width: 70%;
}

.mdi-check {
  &:hover {
    color: rgb(23, 219, 23);
  }
}

.h-52 {
  height: 52px;
}

.h-71vh {
  height: 72vh;
}

.video-cont {
  align-self: baseline;
  box-shadow: 1px 1px 3px 2px lightgrey;
  border-radius: 5px;

  & video {
    object-fit: contain;
    width: 100%;
    vertical-align: middle;
    border-radius: 5px;
  }
}

.P-delete-video {
  & .only-icon-btn {
    font-size: $txt20;
  }
}

.P-categories-type {
  color: white;
  background-color: $primary-color;
  font-weight: 500;
  border-radius: 5px;
}
</style>
